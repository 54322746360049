import { render, staticRenderFns } from "./editorreport.vue?vue&type=template&id=01982e92&scoped=true"
import script from "./editorreport.vue?vue&type=script&lang=js"
export * from "./editorreport.vue?vue&type=script&lang=js"
import style0 from "./editorreport.vue?vue&type=style&index=0&id=01982e92&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01982e92",
  null
  
)

export default component.exports